body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'AvenirBook';
    src: url('./fonts/AvenirBook/avenir-book.eot');
    src: url('./fonts/AvenirBook/avenir-book.eot') format('embedded-opentype'),
    url('./fonts/AvenirBook/avenir-book.woff2') format('woff2'),
    url('./fonts/AvenirBook/avenir-book.woff') format('woff'),
    url('./fonts/AvenirBook/avenir-book.ttf') format('truetype'),
    url('./fonts/AvenirBook/avenir-book.svg#AvenirBook') format('svg');
}